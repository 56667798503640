<template>
  <b-card title="Transaction History">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.label ==='Photo'">
          <img
            :src="require(`@/assets/images/pages/${props.row.photo}.jpg`)"
            :alt="props.row.photo"
            class="medium-photo-size"
          >
        </span>
        <span v-else-if="props.column.label === 'Actions'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Deposit/ Withdraw Wall</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Transaction History</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
          {{ props.row[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10','15']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import {
  BCard, BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      show: true,
      columns: [
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Commission',
          field: 'commission',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters(['transactionHistory']),
    rows() {
      if (this.transactionHistory) {
        return JSON.parse(JSON.stringify(this.transactionHistory))
      }
      return []
    },
    direction() {
      if (this.$store.getters['appConfig/direction'] === 'rtl') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.getInfo()
  },
  methods: {
    ...mapActions(['getTransactionHistoryInfo']),
    async getInfo() {
      await this.getTransactionHistoryInfo()
    },
  },
}
</script>
<style lang="scss">
    .table-search
    {
        padding: 0;
    }
</style>
